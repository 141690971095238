<template>
  <table class="ui selectable striped table">
    <thead>
      <tr>
        <th>Job Number with Sequence</th>
        <!-- <th>Due Date</th> -->
        <th>Status</th>
        <th>Sales Rep</th>
        <th colspan="2">Customer</th>
      </tr>
    </thead>
    <tbody>
      <tr v-if="loading">
        <td colspan="4"><i class="loading spinner icon"></i></td>
      </tr>
      <tr v-else-if="orders.length === 0">
        <td colspan="4">No orders match the search criteria.</td>
      </tr>
      <router-link
        custom
        v-slot="{ navigate }"
        v-for="order in orders"
        :key="order.id"
        :to="{ name: 'Order', params: { id: order.id } }"
      >
        <tr @click="navigate">
          <td>{{ order.reference_number }}-{{ order.sequence }}</td>
          <!-- <td>
            {{
              order.date
                ? DateTime.fromISO(order.date).toLocaleString(DateTime.DATE_MED)
                : "-"
            }}
          </td> -->
          <td
            :class="{
              grey: order.status === 'DR',
              green:
                !['DR', 'CA', 'FI'].includes(order.status) &&
                !order.art_redo &&
                !order.creative_redo &&
                !order.proof_redo,
              black: order.status === 'FI',
              orange: order.art_redo || order.creative_redo || order.proof_redo,
              blue: order.status === 'CA'
            }"
          >
            {{ order.status === "DR" ? "Draft" : "" }}
            {{ order.status === "CR" ? "Creating" : "" }}
            {{ order.status === "CA" ? "Creative approval" : "" }}
            {{ order.status === "PR" ? "Proofing" : "" }}
            {{ order.status === "PA" ? "Proof approval" : "" }}
            {{ order.status === "CO" ? "Converting" : "" }}
            {{ order.status === "FI" ? "Finished" : "" }}
            <i
              v-if="order.art_redo || order.creative_redo || order.proof_redo"
              class="warning icon"
              title="One or more assets are being reworked."
            ></i>
          </td>
          <td>{{ order.sales_rep_email }}</td>
          <td>{{ order.customer_email }}</td>
          <td><i class="right chevron icon" /></td>
        </tr>
      </router-link>
    </tbody>
  </table>
</template>

<script>
import { mapState } from "vuex";
import { DateTime } from "luxon";

export default {
  props: {
    loading: Boolean
  },

  data() {
    return {
      DateTime
    };
  },

  computed: {
    ...mapState(["orders"])
  }
};
</script>
