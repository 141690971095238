<template>
  <div class="ui raised segment">
    <div class="ui grid">
      <div class="four wide column">
        <div class="ui form">
          <div class="field">
            <select
              class="ui clearable selection dropdown"
              v-model="filters.parameter"
            >
              <option :value="null">All parameters</option>
              <option value="C">Customer Email</option>
              <option value="R">Job Number with Sequence</option>
              <option value="A">Design ID</option>
            </select>
          </div>
          <div class="field">
            <label>Status</label>
            <select
              class="ui clearable selection dropdown"
              v-model="filters.status"
            >
              <option :value="null">All</option>
              <option value="DR">Draft</option>
              <option value="CR">Creating</option>
              <option value="PC">Art Approval</option>
              <option value="PR">Proofing</option>
              <option value="PA">Proof Approval</option>
                <option value="CO">Converting</option>
                <option value="FI">Finished</option>
            </select>
          </div>
        </div>
      </div>
      <div class="twelve wide column">
        <div class="ui form">
          <div class="field">
            <input
              @input="debounce(filters, 'query', $event)"
              :value="filters.query"
              :disabled="!filters.parameter"
              placeholder="Search by parameter"
            />
          </div>
          <div class="three fields">
            <div v-if="profile.role !== 'R'" class="field">
              <label>Sales rep</label>
              <select
                class="ui clearable selection dropdown"
                v-model="filters.sales_rep"
              >
                <option :value="null">All</option>
                <option
                  v-for="sales_rep in nonCSRUsers"
                  :key="sales_rep.id"
                  :value="sales_rep.id"
                  >{{ sales_rep.user.email }}</option
                >
              </select>
            </div>
            <!-- <div class="field">
              <label>Due date</label>
              <div class="ui calendar" id="rangestart">
                <div class="ui input">
                  <input type="text" placeholder="Start" />
                </div>
              </div>
            </div>
            <div class="field">
              <label>&nbsp;</label>
              <div class="ui calendar" id="rangeend">
                <div class="ui input">
                  <input type="text" placeholder="End (optional)" />
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "@/utils/debounce";
import { mapActions, mapState } from "vuex";

const $ = window.jQuery;

export default {
  data() {
    return {
      filters: {
        parameter: null,
        query: null,
        status: this.$route.query.status,
        sales_rep: null,
        customer: null,
        date_start: null,
        date_end: null
      },

      debounce
    };
  },

  computed: {
    nonCSRUsers() {
      return this.users.filter(u => u.role !== "S");
    },

    ...mapState(["profile", "users"])
  },

  methods: {
    ...mapActions(["loadUsers"])
  },

  watch: {
    filters: {
      handler(filters) {
        let query = "";

        switch (filters.parameter) {
          case "C":
            query += `&customer=${filters.query ? filters.query : ""}`;
            break;
          case "R":
            query += `&reference_number=${filters.query ? filters.query : ""}`;
            break;
          case "A":
            query += `&artwork=${filters.query ? filters.query : ""}`;
            break;
          default:
            this.filters.query = null;
        }

        if (filters.status) {
          query += `&status=${filters.status}`;
        }

        if (filters.sales_rep) {
          query += `&sales_rep=${filters.sales_rep}`;
        }

        // if (filters.date_from) {
        //   query += `&date_from=${filters.date_from}`;
        // }

        // if (filters.date_to) {
        //   query += `&date_to=${filters.date_to}`;
        // }

        if (query.charAt(0) === "&") {
          query = "?" + query.substring(1);
        }

        this.$emit("update", query);
      },
      deep: true
    }
  },

  mounted() {
    if (this.profile.role !== "R") {
      this.loadUsers();
    }

    $(".ui.dropdown").dropdown();

    // let vm = this;

    // $("#rangestart").calendar({
    //   type: "date",
    //   endCalendar: $("#rangeend"),
    //   onChange(date) {
    //     if (date) {
    //       date.setHours(0, 0, 0);
    //       vm.filters.date_from = date.toISOString().substr(0, 10);
    //     } else {
    //       vm.filters.date_from = null;
    //     }
    //   }
    // });
    // $("#rangeend").calendar({
    //   type: "date",
    //   startCalendar: $("#rangestart"),
    //   onChange(date) {
    //     if (date) {
    //       date.setHours(23, 59, 59);
    //       vm.filters.date_to = date.toISOString().substr(0, 10);
    //     } else {
    //       vm.filters.date_to = null;
    //     }
    //   }
    // });
  }
};
</script>
