let timeout;

export default function(obj, attr, event) {
  if (timeout) {
    clearTimeout(timeout);
  }
  timeout = setTimeout(() => {
    obj[attr] = event.target.value;
  }, 500);
}
