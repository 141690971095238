<template>
  <div class="ui fluid container">
    <orders-search @update="search" />
    <div class="ui raised segment">
      <orders-table :loading="loading.orders" />

      <p v-if="paginator.count">
        Displaying results {{ (paginator.currentPage - 1) * 10 + 1 }}-{{
          (paginator.currentPage - 1) * 10 + 11 > paginator.count
            ? paginator.count
            : (paginator.currentPage - 1) * 10 + 11
        }}
        of {{ paginator.count }}.
      </p>
      <div v-if="paginator.count" class="ui pagination menu">
        <a
          v-for="page in paginator.pages"
          :key="page"
          :class="{ active: page === paginator.currentPage }"
          @click="page !== paginator.currentPage ? changePage(page) : () => {}"
          class="item"
        >
          {{ page }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import OrdersTable from "@/components/OrdersTable";
import OrdersSearch from "@/components/OrdersSearch";
import { mapActions } from "vuex";

const $ = window.jQuery;

export default {
  components: {
    OrdersTable,
    OrdersSearch
  },

  data() {
    return {
      lastQuery: "",

      paginator: {
        count: 0,
        currentPage: 1,
        pages: null
      },

      loading: {
        orders: true
      }
    };
  },

  methods: {
    search(query) {
      this.paginator.currentPage = 1;
      this.lastQuery = query;
      this.loading.orders = true;
      this.loadOrders({ query }).then(data => {
        this.paginator.count = data.count;
        this.paginator.pages = data.pages;
        this.loading.orders = false;
      });
    },

    changePage(page) {
      this.paginator.currentPage = page;
      let query;
      if (this.lastQuery.charAt(0) === "?") {
        query = this.lastQuery + `&page=${page}`;
      } else {
        query = this.lastQuery + `?page=${page}`;
      }

      this.loading.orders = true;
      this.loadOrders({ query }).then(data => {
        this.paginator.count = data.count;
        this.paginator.pages = data.pages;
        this.loading.orders = false;
      });
    },

    ...mapActions(["loadOrders", "loadUsers"])
  },

  created() {
    let query = "";
    if (this.$route.query.status) {
      if (Array.isArray(this.$route.query.status)) {
        query = "?";
        let first = true;
        this.$route.query.status.forEach(s => {
          if (!first) {
            query += "&";
          }
          first = false;
          query += `status=${s}`;
        });
      } else {
        query = `?status=${this.$route.query.status}`;
      }
    }

    if (this.$route.query.has_issues) {
      if (query === "") {
        query = "?";
      }
      query += `has_issues=true`;
    }

    this.loadOrders({ query }).then(data => {
      this.paginator.count = data.count;
      this.paginator.pages = data.pages;
      this.loading.orders = false;
    });
  },

  mounted() {
    $(".ui.dropdown").dropdown();

    $("#rangestart").calendar({
      type: "date",
      endCalendar: $("#rangeend")
    });
    $("#rangeend").calendar({
      type: "date",
      startCalendar: $("#rangestart")
    });
  }
};
</script>

<style scoped>
.container {
  padding: 0 3em 0 3em;
}
</style>
